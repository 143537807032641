@font-face {
  font-family: "Trenda-Regular";
  src: local("Trenda-Regular.woff"),
    url("./fonts/Trenda-Regular.woff") format("truetype");
}

.App {
  font-family: "Trenda-Regular";
  text-align: center;
  height: 100dvh;
  width: 100vw;
}

body {
  padding: 0;
  margin: 0;
}

.react-tooltip {
  z-index: 2147483647;
}